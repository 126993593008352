import { Modal, ModalFooter, ModalMessage, ModalTitle } from '@components/modal';
import { router } from '@components/router';
import { AsyncForm, FormGroup } from '@components/async-form';
import { IcoAcademicCap, IcoLibrary } from '@components/icons';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { useCurrentTenant } from '@components/router/session-context';
import { useState } from 'preact/hooks';
import { CoursePrivacySettings, CoursePrivacyState } from '@components/course-privacy-settings';

type Props = {
  isProduct: boolean;
  onCancel: () => void;
};

const store = rpx.guideBundles;

export function CreateCourseModal({ isProduct, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const courseLabel = isProduct ? 'product' : terminology.course;
  const [featuresState, setFeaturesState] = useState<CoursePrivacyState>({
    hidePeople: isProduct,
    hideDiscussions: isProduct,
    restrictStudentDiscussions: isProduct,
    chatEnabled: !isProduct,
  });

  async function onSubmit(values: { title: string }) {
    const { courseId, lessonId } = await rpx.courses.createCourse({
      isProduct,
      title: values.title,
      ...featuresState,
    });
    router.goto(
      URLS.guide.lesson({
        isProduct,
        courseId,
        lessonId,
      }),
    );
  }

  return (
    <Modal isOpen={true} onCancel={onCancel}>
      <AsyncForm onSubmit={onSubmit}>
        <IcoAcademicCap
          stroke-width="1"
          class="w-24 h-24 m-auto mt-4 mb-4 rounded-full text-white bg-indigo-500 p-3"
        />
        <ModalTitle centered={true}>Create a new {courseLabel}</ModalTitle>
        <ModalMessage>
          <label class="block mb-2">Enter the title of your new {courseLabel}.</label>
          <FormGroup prop="title" class="w-full mb-6">
            <input
              type="text"
              placeholder={`${isProduct ? 'Product' : terminology.Course} title`}
              name="title"
              class="ruz-input"
            />
          </FormGroup>
          {!isProduct && (
            <CoursePrivacySettings state={featuresState} setState={setFeaturesState} />
          )}
        </ModalMessage>
        <ModalFooter>
          <BtnSecondary type="button" onClick={onCancel}>
            Cancel
          </BtnSecondary>
          <BtnPrimary>Create {courseLabel}</BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}

export function CreateBundleModal({ onCancel }: Props) {
  async function onSubmit(data: any) {
    const courseId = await store.createBundle({
      title: data.title,
    });
    router.goto(
      URLS.guide.course({
        courseId,
      }),
    );
  }

  return (
    <Modal isOpen={true} onCancel={onCancel}>
      <AsyncForm onSubmit={onSubmit}>
        <IcoLibrary
          stroke-width="1"
          class="w-24 h-24 m-auto mt-4 mb-4 rounded-full text-white bg-indigo-500 p-3"
        />
        <ModalTitle centered={true}>Create a new bundle</ModalTitle>
        <ModalMessage>
          <label class="block mb-2">Enter the title of your new bundle.</label>
          <FormGroup prop="title" class="w-full mb-6">
            <input type="text" placeholder="bundle title" name="title" class="ruz-input" />
          </FormGroup>
        </ModalMessage>
        <ModalFooter>
          <BtnSecondary type="button" onClick={onCancel}>
            Cancel
          </BtnSecondary>
          <BtnPrimary>Create bundle</BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}
