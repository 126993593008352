import { showError } from '@components/app-error';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Case } from '@components/conditional';
import { DateInput } from '@components/date-picker';
import { Modal, ModalTitle, ModalMessage, ModalFooter } from '@components/modal';
import { router } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { Course } from 'server/types';
import { URLS } from 'shared/urls';
import { CoursePrivacySettings, CoursePrivacyState } from '@components/course-privacy-settings';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
  onCancel: () => void;
}

const store = rpx.instantCourses;

export function CopyCourseTemplateModal({ course, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const { id, title } = course;
  const [isCopying, setIsCopying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modulesData, setModulesData] = useState<
    RpxResponse<typeof store.getCourseModules> | undefined
  >();
  const [newDate, setNewDate] = useState<Date | undefined>(() => dayjs().add(7, 'days').toDate());
  const [featuresState, setFeaturesState] = useState<CoursePrivacyState>({
    hidePeople: false,
    hideDiscussions: false,
    chatEnabled: true,
    restrictStudentDiscussions: false,
  });

  const modules = modulesData?.modules ?? [];
  const firstDate = modulesData?.firstDate;

  useEffect(() => {
    async function fetchFirstDates() {
      try {
        const result = await store.getCourseModules({ courseId: id });
        setModulesData(result);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchFirstDates();
  }, [id]);

  async function copyCourse(values: { title: string }) {
    setIsCopying(true);

    try {
      const { newCourseId, firstLessonId } = await store.copyInstantCourse({
        ...featuresState,
        id,
        title: values.title || title,
        startDateDiff:
          firstDate?.date && newDate ? dayjs(newDate).diff(firstDate.date, 'minute') : undefined,
      });
      router.goto(
        URLS.guide.lesson({
          courseId: newCourseId,
          lessonId: firstLessonId,
        }),
      );
    } catch (err) {
      showError(err);
    } finally {
      setIsCopying(false);
    }
  }

  return (
    <Modal isOpen onCancel={onCancel}>
      <AsyncForm onSubmit={copyCourse}>
        <ModalTitle centered={true}>Create {terminology.course} from template</ModalTitle>
        <ModalMessage class="space-y-8">
          <div>
            <label class="block mb-2">Enter the title of your new {terminology.course}.</label>
            <FormGroup prop="title" class="w-full">
              <input type="text" placeholder={title} name="title" class="ruz-input" />
            </FormGroup>
          </div>

          <Case when={!isLoading} fallback={<Spinner />}>
            {firstDate?.date && (
              <div>
                <label class="block mb-2">
                  Set the first {firstDate.type} date of this {terminology.course}. We'll adjust the
                  remaining
                  {terminology.module}, message and {terminology.meeting} dates accordingly.
                </label>
                <div class="flex items-center">
                  <DateInput name="newDate" value={newDate} onChange={setNewDate} includeTime />
                </div>
              </div>
            )}
          </Case>

          {modules.length > 0 && (
            <div class="flex flex-col">
              <h3 class="text-sm uppercase font-bold text-gray-500 mb-2">{terminology.Modules}</h3>
              <ol class="list-inside list-decimal">
                {modules.slice(0, 5).map((l) => (
                  <li key={l.id} class="text-ellipsis overflow-hidden list-decimal">
                    {l.title}
                  </li>
                ))}
              </ol>
              {modules.length > 5 && <footer class="mt-2">...and {modules.length - 5} more</footer>}
            </div>
          )}
          <CoursePrivacySettings state={featuresState} setState={setFeaturesState} />
        </ModalMessage>

        <ModalFooter>
          <BtnSecondary class="mr-3" type="button" onClick={onCancel}>
            Cancel
          </BtnSecondary>
          <BtnPrimary disabled={isLoading} isLoading={isCopying}>
            Create {terminology.course}
          </BtnPrimary>
        </ModalFooter>
      </AsyncForm>
    </Modal>
  );
}
